.text-nunito-fs8-fw400-gray500 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-500);
  border: 0;
}

.text-nunito-fs12-fw400-white {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--white);
}

.text-nunito-fs12-lh37-fw700-white {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 3.7rem;
  color: var(--white);
}

.text-nunito-fs14-lh16-fw400-blue700 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--blue-700);
  border: 0;
}
