table {
  min-width: max-content;
  min-height: max-content;
}

th {
  padding: 0rem 1rem;
}

tbody tr:last-child {
  border-color: transparent;
}

.table-container {
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3.8rem 1rem 3.8rem;
  margin-top: 1.7rem;
  margin-bottom: 1.2rem;
}

.table-container thead {
  border-bottom: 1px solid var(--gray-200);
}

.table-container th {
  font-weight: 700;
  padding-bottom: 1rem;
}

.table-container tr {
  height: 7rem;
  vertical-align: middle;
}



.table-container {
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3.8rem 1rem 3.8rem;
  margin-top: 1.7rem;
  margin-bottom: 1.2rem;
}

.table-text {
  font-family: "Roboto";
  font-style: normal;
  font-size: 1.4rem;
  color: var(--blue-700);
} 