.dashboard-banner-effect {
  height: 100%;
  border-radius: 3rem;
  background-image: url(../../assets/img/image-background-tutorial.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.dashboard-banner-content {
  width: 100%;
  height: 100%;
  background: rgba(42, 59, 90, 0.4);
  border-radius: 3rem;
  padding: 4rem 4.6rem;
  vertical-align: bottom;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dashboard-managed-values-container {
  border-radius: 3rem;
  background-color: var(--white);
  padding: 2rem 2.5rem;
}

.dashboard-managed-values-prefix {
  font-size: 2rem;
}

.dashboard-input-faq {
  background-color: var(--white);
  border-radius: 3rem;
  padding-left: 1.9rem;
  padding-right: 0.8rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

  height: 4rem;

  border: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--blue-700);
}

.dashboard-input-faq::placeholder {
  color: var(--gray-500);
}

@media (max-width: 406px) {
  .dashboard-button-submit-faq {
    flex-grow: 1;
  }
}