.body-content {
  padding: 1.2rem 0.8rem 1.8rem 2rem;
  min-height: 100%;
}

.body-main-container {
  padding: 4.4rem 3.4rem 2.2rem 3.4rem;
  background-color: var(--gray-300);
  border-radius: 3rem;
  min-height: 100%;
} 

.body-main {
  min-height: 100%;
  width: calc(100% - 11rem);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .body-main {
    width: 100%;
  }
}