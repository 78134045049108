.container-sidebar {
  width: 11rem;
}

.sidebar {
  width: 11rem;
  background: linear-gradient(
    180deg,
    var(--blue-600) 0%,
    var(--pink-400) 49.48%,
    var(--orange-400) 100%
  );
  z-index: 10;
  border-bottom-right-radius: 3rem;
  border-top-right-radius: 3rem;
  top: 1.2rem;
  left: 0;
  position: fixed;
  padding: 3.5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.sidebar:hover {
  width: 30rem;
  padding: 3.5rem 3.5rem;
  align-items: flex-end;
}

@media (max-width: 1023px) {
  .container-sidebar,
  .sidebar {
    display: none;
  }
}