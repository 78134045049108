.conciliation-dropdonw-menu-content {
    width: 15rem;
    height: fit-content;
    padding: 0.3rem;

    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 0 0.4rem 0.1rem rgba(0, 0, 0, 0.7);

    margin-top: 0.7rem;
}

.conciliation-dropdonw-checbox-item {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    color: var(--blue-600);
    cursor: pointer;
    padding: 1rem 1.5rem;
}

.conciliation-dropdonw-checbox-item:checked {
    color: #fff;
    background-color: var(--blue-600);
}

.conciliation-dropdonw-checbox-item:hover {
    color: #fff;
    background-color: var(--blue-600);
}