.text-montserrat-fs16-fw400 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
}

.text-montserrat-fs16-lh19-fw400 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.text-montserrat-fs16-lh19-fw400-blue700 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--blue-700);
}

.text-montserrat-fs16-lh19-fw400-white {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--white);
}

.text-montserrat-fs16-lh20-fw400-white {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--white);
}