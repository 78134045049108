.datacard {
  border: 1.5px solid var(--gray-500);
  border-radius: 3rem;
  flex-grow: 1;
  padding: 0rem 2.6rem;
}

.datacard-text {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;       
  text-overflow: ellipsis;
}
