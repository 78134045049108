.collaborator-table-negativebalance-action {
  height: 4rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  color: var(--blue-700);
  background-color: var(--white);
  border: 1.5px solid var(--blue-600);
  margin-bottom: 0.5rem;

  padding: 1.2rem 1.5rem;
  border-radius: 3rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.collaborator-table-negativebalance-action:hover {
  color: var(--white);
  background-color: var(--blue-700);
  border: 1.5px solid var(--blue-600);
}