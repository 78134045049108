.dashboard-indicator-container {
  min-height: 15.6rem;
  border-radius: 3rem;
  padding: 1rem 3rem;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-around;
}

.dashboard-indicator-separator {
  border: 1px solid #dadada;
  background-color: #dadada;
  height: 40%;
}

@media (max-width: 1666px) {
  .dashboard-indicator-container {
    gap: 0rem
  }

  .dashboard-indicator-separator {
    display: none;
  }
}
 