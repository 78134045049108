.collaborator-panel-stageprogress-container {
    padding: 2.7rem 0rem;
}

.collaborator-panel-stageprogress-bar {
  border: 0.1rem solid var(--gray-500);
  background-color: var(--gray-500);
  height: 0.01rem;
  width: 5rem;
}

.collaborator-panel-stageprogress-indicator {
  width: 4rem;
  height: 4rem;
  padding: 1rem 1rem;
  border-radius: 50%;
  background-color: var(--gray-600);
  display: flex;
  align-items: center;
  justify-content: center;
}

.collaborator-panel-stageprogress-indicator-active {
  background-color: var(--blue-700);
  color: var(--white);
}

.collaborator-panel-stageprogress-indicator-activated {
  background-color: var(--green-300);
  color: var(--white);
}

.collaborator-panel-stageprogress-indicator-inactive {
  background-color: var(--gray-600);
  color: var(--gray-500);
}
