* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
}

a,
a:visited,
a:active {
  text-decoration: none;
  cursor: pointer;
}