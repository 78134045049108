.container-modal-md {
  min-height: 35rem;
  width: 50rem;
  max-width: 90%;

  border-radius: 3rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-modal-lg {
  min-height: 50rem;
  width: 100rem;
  max-width: 90%;

  border-radius: 3rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*----------------------------*/
.container-input {
  min-height: 4.1rem;
  background-color: hsl(0, 0%, 100%);
  border-color: #3d5375;
  border-radius: 3rem;
  border-style: solid;
  border-width: 1px;

  display: flex;
  flex-direction: row;
  align-items: center;

  overflow: hidden;

  padding-left: 2rem;
}

.collaborators-input {
  outline: unset;
  color: var(--blue-700);
  font-size: 1.4rem;
  font-family: 'Roboto', sans-serif;
  border: none;
  min-width: 80%;
}

.container-input button {
  height: 100%;
  min-width: 20%;
  padding: 0 1.5rem;
  border-radius: 0;
  background-color: var(--blue-700);

  transition: all 1s ease-in-out;
}

.container-input button:hover {
  filter: opacity(0.8);
}