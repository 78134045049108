.input-profile {
  border: 0;
  background-color: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 2rem;
  border-bottom: 1px solid var(--gray-200);
  padding: 0rem  0rem 1rem 0rem;
}

.modal-profile {
  background-color: #fff;
  border-radius: 3rem;
  padding: 3rem;
  /* width: 60rem; */
  width: 100%;
  /* max-width: 90%; */
}

.section-profile {
  margin-top: 3rem;
}