.header {
  border-bottom: 0.1rem solid var(--gray-200);
  padding-bottom: 2.9rem;
}  

.header-input-container {
  background-color: var(--white);
  border-radius: 3rem;
  padding-left: 1.9rem;
  padding-right: 0.8rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.header-input {
  border: none;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 3.7rem;
  color: var(--blue-700);
  width: 100%;
}

.header-input::placeholder {
  color: var(--gray-500);
}

.header-input-button {
  padding: 0.9rem;
  background-color: var(--gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-button {
  width: 6rem;
  height: 5rem;
  background: linear-gradient(
    180deg,
    /* var(--blue-600) 0%, */
    var(--pink-400) 49.48%,
    var(--orange-400) 100%
  );
  font-weight: bold;
}

/* @media (max-width: 1023px) {
  .header-button {
    display: none;
  }
} */