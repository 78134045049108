.container-loading-table {
    background-color: var(--white);
    border-radius: 3rem;
    padding: 2rem 3.8rem 1rem 3.8rem;
    margin-top: 1.7rem;
    margin-bottom: 1.2rem;
    min-height: 70rem;
}

.label-no-results-found {
    color: #9F9F9F;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
}