.feedback-modal {
    border-radius: 3rem;
    background-color: var(--white);
    width: 40rem;
    max-width: 90%;
}

.title-modal-notification {
    font-family: 'Roboto', sans-serif;
    font-size: 2.6rem;
    color: var(--green-300);
    text-align: center;
    margin-bottom: 1rem;
}