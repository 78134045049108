.close-button-shutdown-worksheet {
    transform: scale(0.7);
}

.drag-drop-shutdown-worksheet {
  height: 13rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}