.offcanvas {
  background: linear-gradient(
    180deg,
    var(--blue-600) 0%,
    var(--pink-400) 49.48%,
    var(--orange-400) 100%
  );
}

.offcanvas.offcanvas-start {
  width: max-content;
  max-width: 30rem;
  padding: 0rem 1rem 0rem 2rem;
}

@media (min-width: 1024px) {
  .offcanvas-backdrop,
  .offcanvas {
    display: none;
  }
}
