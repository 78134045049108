.collaborator-actions-container {
  background-color: var(--white);
  padding: 0.5rem 3.3rem 2.4rem 3.3rem;
  border-radius: 0rem 0rem 3rem 3rem;
  height: fit-content;
}

@media (max-width: 767px) {
  .collaborators-buttonfilter-container {
    flex-wrap: wrap;
  }
}