:root {
  font-size: 62.5%;
  --blue-600: #3d5375;
  --blue-700: #2a3b5a;
  --black-400: #3a3a3a;
  --white: #fff;
  --green-300: #62d6ba;
  --red-300: #f15d5a;
  --pink-400: #c0456e;
  --pink-500: #bb527d;
  --orange-400: #e38952; 
  --orange-500: #f79463;
  --gray-100: #f1f1f1;
  --gray-200: #dadada;
  --gray-300: #f3f3f3;
  --gray-400: #b9b9b9;
  --gray-500: #c0c0c0; 
  --gray-600: #d9d9d9;
  --gray-700: #707070
}
