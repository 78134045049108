.feedback-modal {
    border-radius: 3rem;
    background-color: var(--white);
    width: 40rem;
    max-width: 90%;
}


.dialog-modal-container-icon {
    animation: opacityKeyframes 1s linear backwards;
}

@keyframes opacityKeyframes {
    from {
        opacity: 0;
    }
}

.dialog-modal-paragraphy {
    font-size: 1.8rem;
    font-familly: "Roboto",
        sans-serif;
    color: var(--blue-700);
    text-align: center;
    opacity: 1;
    margin-top: 2rem;
    animation: opacityKeyframes 1s linear backwards;
}

.dialog-modal-button {
    margin-top: 2.5rem;
    animation: opacityKeyframes 1s linear backwards;

}