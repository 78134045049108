.collaborator-shutdownlist-index {
  height: 3.5rem;
  width: 4rem;
  border-radius: 50%;
  background-color: var(--gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 436px) {
  .collaborator-shutdownlist-index {
    display: none;
  }
}

.collaborator-shutdownlist-value {
  width: 100%;
  border-radius: 3rem;
  border: 0.1rem solid var(--gray-200);
  padding: 1rem 1.5rem;
  display: block;
}

.collaborator-shutdownlist-value::placeholder {
  color: var(--gray-100);
  font-size: 1.2rem;
}


@media (max-width: 530px) {
  .collaborator-shutdownlist-listitem-button {
    flex-grow: 1;
  }
}
