.login-form-inmano-icon {
  width: 20.1rem;
  margin-bottom: 3.2rem;
}

.login-form-message {
  margin-bottom: 3.3rem;
}

.login-form-label {
  margin-bottom: 1.7rem;
}

.login-form-input {
  height: 3rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--black-400);
  border: 0;
  border-bottom: 1px solid var(--blue-600);
  outline: none;
  margin-bottom: 3.3rem;
}

.login-loginfeedback-success {
  border: 0.4rem solid var(--green-300);
}

.login-loginfeedback-error {
  border: 0.6rem solid var(--red-300);
}