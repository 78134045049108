.sidebar-nav {
  margin-top: 5rem;
  gap: 5rem;
}

.sidebar-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 1.6rem;
  text-align: right;
  color: var(--white);
}

.sidebar-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .sidebar-mobile {
    display: block;
  }
}

/* .sidebar-logout-button {
  background-color: transparent;
  border: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: right;
  color: var(--white);
} */