/* FONT SIZE 12 */

.text-roboto-fs12-lh14-fw400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.text-roboto-fs12-lh14-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--blue-700);
}

.text-roboto-fs12-lh14-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--blue-700);
}

.text-roboto-fs12-lh14-fw400-white {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--white);
}

/* FONT SIZE 14 */

.text-roboto-fs14-lh16-fw400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.text-roboto-fs14-lh16-fw400-white {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--white);
}

.text-roboto-fs14-lh16-fw400-gray400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--gray-400);
}

.text-roboto-fs14-fw400-red300 {
  font-family: "Roboto";
  font-style: normal;
  font-size: 1.4rem;
  color: var(--red-300);
}

.text-roboto-fs14-lh16-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--blue-700);
}
 
.text-roboto-fs14-lh16-fw400-red300 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--red-300);
}

.text-roboto-fs16-lh16-fw400-red300 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: var(--red-300);
}

.text-roboto-fs15-lh16-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: var(--blue-700);
}

.text-roboto-fs14-lh16-fw400-black400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--black-400);
}

.text-roboto-fs14-lh16-fw700-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--blue-600);
}

.text-roboto-fs14-lh16-fw700-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--blue-700);
}

.text-roboto-fs14-lh16-fw700-orange500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--orange-500);
}

.text-roboto-fs14-lh16-fw700-pink500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--pink-500);
}

.text-roboto-fs14-lh20-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--blue-600);
}

.text-roboto-fs14-lh29-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.9rem;
  color: var(--blue-600);
}

.text-roboto-fs14-lh29-fw700-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.9rem;
  color: var(--blue-600);
}

.text-roboto-fs14-lh30-fw700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 3rem;
}

/* FONT SIZE 16 */

.text-roboto-fs16-lh18-fw700-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--blue-600);
}

.text-roboto-fs16-lh18-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--blue-600);
}

.text-roboto-fs16-lh18-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--blue-700);
}

.text-roboto-fs16-lh18-fw700-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: var(--blue-700);
}

.text-roboto-fs16-lh19-fw400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.text-roboto-fs16-lh19-fw700-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--blue-600);
}

.text-roboto-fs16-lh19-fw700-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--blue-700);
}

.text-roboto-fs16-lh20-fw400-white {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--white);
}

/* FONT SIZE 20 */

.text-roboto-fs20-lh20-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  color: var(--blue-600);
}

.text-roboto-fs20-lh23-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: var(--blue-600);
}

.text-roboto-fs20-lh23-fw400-orange500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: var(--orange-500);
}

.text-roboto-fs20-lh23-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: var(--blue-700);
}

.text-roboto-fs20-lh23-fw400-white {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: var(--white);
}

.text-roboto-fs20-lh29-fw400-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.9rem;
  color: var(--blue-600);
}

.text-roboto-fs20-lh29-fw700-blue600 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.9rem;
  color: var(--blue-600);
}

.text-roboto-fs20-lh30-fw700-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: var(--blue-700);
}

/* FONT SIZE 30 */

.text-roboto-fs30-lh30-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3rem;
  color: var(--blue-700);
}

.text-roboto-fs30-lh30-fw400-pink400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3rem;
  color: var(--pink-400);
}

.text-roboto-fs30-lh30-fw700-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  color: var(--blue-700);
}

.text-roboto-fs30-lh35-fw400-blue700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  color: var(--blue-700);
}

.text-roboto-fs30-lh35-fw400-orange500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  color: var(--orange-500);
}

.text-roboto-fs30-lh35-fw700-white {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
  color: var(--white);
}

/* FONT SIZE 50 */

.text-roboto-fs50-lh30-fw400-orange500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  line-height: 3rem;
  color: var(--orange-500);
}
