.dashboard-collapse-faq {
  height: max-content;
  padding: 2.9rem 3.3rem;
  border-radius: 3rem;
  background: var(--white);
}

.dashboard-collapse-faq-arrow {
  width: 1.2rem;
  height: 1.2rem;
}

.dashboard-collapse-faq-container {
 max-width: fit-content;
 padding: 3rem 2.5rem;
}
