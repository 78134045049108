.upload {
  background: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 3rem;
  padding: 2.7rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  position: relative;
}

.upload-span-select-file {
  border-radius: 3rem;
  padding: 1rem 1.5rem;
  color: var(--blue-700);
  background-color: transparent;
  border: 0.1rem solid var(--blue-700);
}

.upload-success {
  border-color: var(--green-300);
}

.upload-error {
  border-color: var(--red-300);
}

@media (max-width: 470px) {
  .upload-file-icon {
    display: none;
  }
}
