.login-background-image {
  background-image: url(../../assets/img/image-background-login.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3rem;
}

@media (max-width: 1024px) {
  .login-background-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}