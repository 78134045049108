.administrator-panel {
  width: 114.2rem;
  max-width: 90%;
  max-height: 90%;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 3.8rem 4.3rem;
}

.administrator-panel-input {
  border: 0;
  background-color: transparent;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 2rem;
  margin-top: 1rem;
}

.administrator-panel-container-input {
  padding-top: 3.6rem;
}

.administrator-panel-functions-container {
  width: 100%;
  border: 1.5px solid var(--blue-700);
  border-radius: 3rem;
  margin-top: 2rem;
  padding: 2.5rem 2.8rem;
}

.administrator-panel-function {
  padding: 2.5rem 0 1.6rem 0;
  border-top: 1px solid #dadada;
}

.administrator-panel-function .col-1 input[type="checkbox"] {
  border: 1.5px solid #707070;
  border-radius: 5px;
  transform: scale(1.5);
}

.administrator-panel-function:first-child {
  border-top: 0;
}

.administrator-panel-configurenotify-container {
  padding-top: 2rem;
  padding-bottom: 1rem;
  gap: 1rem;
}