@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    animation: rotate 1s linear infinite;
    background: var(--blue-600);
    border-radius: 50%;
    position: relative;
  }
  
  .spinner::before,
  .spinner::after {
    content: '';
    position: absolute;
  }
  
  .spinner::before {
    border-radius: 50%;
    background:
      linear-gradient(0deg, var(--blue-600) 50%, var(--pink-400) 100%) 0% 0%,
      linear-gradient(90deg, var(--pink-400) 0%, var(--orange-400) 100%) 100% 0%,
      linear-gradient(180deg, var(--orange-400) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
      linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
  }
  
  .spinner::after {
    background: white;
    border-radius: 50%;
    top: 3%;
    bottom: 3%;
    left: 3%;
    right: 3%;
  }