.collaborator-panel {
  border-radius: 3rem;
  background-color: var(--gray-300);
  max-height: 80%;
  width: 60rem;
  max-width: 95%;
  padding: 2.8rem 4rem;
}

.collaborator-panel-form {
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2.4rem 3.2rem;
}

.collaborator-panel-header {
  padding-bottom: 2.4rem;
  border-bottom: 0.1rem solid var(--gray-200);
}

.collaborator-panel-select-container {
  padding-top: 1.3rem;
  padding-bottom: 2.4rem;
  border-bottom: 0.1rem solid var(--gray-200);
}

.collaborator-panel-input-container {
  margin-top: 2.1rem;
}

.collaborator-panel-inputlabel {
  width: 100%;
  height: 5rem;
  border-radius: 3rem;
  border: 1px solid var(--gray-500);
  padding: 0.1rem 1.8rem 0.6rem 1.8rem;
}

.collaborator-panel-button-container {
  margin-top: 3rem;
}

.collaborator-grid-internal-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.6rem;
  grid-row-gap: 1rem;
}
