.conciliations-contestation-panel {
  border-radius: 3rem;
  background-color: var(--white);
  padding: 4rem 4rem;
  width: 100%;
}

.conciliations-contestation-panel-textarea {
  border: 1px solid #dadada;
  border-radius: 3rem;
  width: 100%;
  min-height: 25rem;
  padding: 1.3rem;
}